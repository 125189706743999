import React from "react";
import NetImage from "../../Assets/image8.JPG";
import "./aboutus.css";
const AboutUs = () => {
  return (
    <div>
      <div className="img-container">
        <img src={NetImage} alt="" />
        <div className="img-abttext">
          <div class="gradient-text">ABOUT US</div>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. <br /> Vel,
            deleniti?
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
