import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import SwiperSlider from "./components/carousel/SwiperSlider";
import Aboutacademy from "./components/AboutAcademy/Aboutacademy";
import MoreAcademy from "./components/MoreAboutAcademy/MoreAcademy";
import Coaches from "./components/Coaches/Coaches";
import Facility from "./components/Facilities/Facility";
import Testimonial from "./components/testimonial/Testimonial";
import Footer from "./components/Footer/Footer";
import AboutUs from "./components/About us/AboutUs";

function Home() {
  return (
    <div>
      <SwiperSlider />
      <Navbar />
      <Aboutacademy />
      <MoreAcademy />
      <Coaches />
      <Facility />
      <Testimonial />
    </div>
  );
}
function About() {
  return (
    <div>
      <Navbar />
      <AboutUs/>
      <Aboutacademy />
      <MoreAcademy />
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* All components displayed on the same home page */}
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;


// .footer {
//   max-width: 100%;
//   height: 50vh;
//   background: rgba(255, 255, 255, 0.1); /* Semi-transparent white overlay */
//   backdrop-filter: blur(10px); /* Blur for glass effect */
//   -webkit-backdrop-filter: blur(10px); /* Blur for Safari support */
//   border: 3px solid rgba(255, 255, 255, 0.2); /* Subtle border for edge highlight */
//   background-image: url("../../Assets/bgthree.jpg");
//   background-size: cover; /* Ensures the image covers the entire container */
//   background-position: center; /* Centers the image */
//   background-attachment: fixed; /* Parallax effect (optional) */
//   background-repeat: no-repeat; /* Prevents the image from repeating */
//   overflow-x: hidden;
// }
