import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import basketlogo from "../../Assets/basketball logo.png";
import sankalplogo from "../../Assets/SANKALP FINAL LOGO.png";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";

const Navbar = () => {
  return (
    <>
      <div className="navbar">
        <img className="sankalplogo" src={sankalplogo} alt="" />
        <div className="icons-nav">
          <FaSquareFacebook className="icon-nav facebook" />
          <FaInstagramSquare className="icon-nav insta" />
          <IoLogoYoutube className="icon-nav youtube" />
        </div>

        <img className="basketlogo" src={basketlogo} alt="" />
        <div className="main-link">
          <Link to={"/"}>Home</Link>
          <Link to={"/aboutus"}>About</Link>
          <Link to={"/conatactus"}>Contact Us</Link>
          <Link to={"/facility"}>Facility</Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
