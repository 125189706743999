import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import sankalplogo from "../../Assets/SANKALP FINAL LOGO.png";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer-div">
          <div className="footer-section1">
            <Link to="/">
            <img src={sankalplogo} alt="" className="footer-logo" />
            </Link>
            <p>
              Lorem ipsum dolor sit amet consectetur, <br /> adipisicing elit.
              Vitae explicabo ab necessitatibus <br /> ut nemo maiores autem
              natus pariatur <br />
              veniam cupiditate.
            </p>
          </div>
          <div className="footer-section2">
            <h2>Links</h2>
            <Link to="/aboutus">About Us</Link>
            <Link to="/contactus">Contact Us</Link>
            <Link to="coaches">Coaches</Link>
            <Link to="location">Location</Link>
          </div>
          <div className="footer-section3">
            <h2>Contact Info</h2>
            <p>Phone: 123-456-7890</p>
            <p>sankalpsports@gmail.com</p>
            <p>102, Spring Lane, London</p>
          </div>
          <div className="footer-section4">
            <h2>Social Media</h2>
            <p>
              Lorem ipsum, dolor sit amet <br /> consectetur adipisicing elit.{" "}
              <br /> Nostrum, vitae.
            </p>
            <div className="socialmedia-icon">
              <FaSquareFacebook className="icon-footer" />
              <FaInstagramSquare className="icon-footer" />
              <IoLogoYoutube className="icon-footer" />
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>
          © 2025 Sankalp Basket Academy • All Rights Reserved
          </p>
        </div>
        <div className="footerblack-div"></div>
      </div>
    </>
  );
};
export default Footer;
