import React, { useState } from "react";
import FancyCarousel from "react-fancy-circular-carousel";
import "react-fancy-circular-carousel/FancyCarousel.css";
import "./facility.css";
import image1 from "../../Assets/aboutimage.JPG";
import image2 from "../../Assets/image8.JPG";
import image3 from "../../Assets/image3.jpeg";
import image4 from "../../Assets/image4.jpeg";
import image5 from "../../Assets/carousel7.jpg";
import image6 from "../../Assets/image6.jpg";
import court from "../../Assets/court.jpg";
import ballogo from "../../Assets/basketball logo.png";

const Facility = () => {
  const [focusElement, setFocusElement] = useState(0);

  const images = [image1, image2, image3, image4, image5, image6];
  const info = [
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  ];

  const heading = [
    "Dribbling",
    "Shooting",
    "Passing",
    "Ball Handling",
    "Agility",
    "Strength",
  ];

  return (
    <>
      <div className="facility-head">
        <h2>
          Featured
          <img src={ballogo} alt="" className="logo-facility" />
          Programs
        </h2>
        <h6>Navigate the Spectrum of Success with Our Programs</h6>
        <img className="facility-court" src={court} alt="" />
        <div className="main-facility">
          <FancyCarousel
            images={images}
            setFocusElement={setFocusElement}
            carouselRadius={220}
            peripheralImageRadius={50}
            centralImageRadius={50}
            focusElementStyling={{ border: "5px solid #e77241" }}
            autoRotateTime={3}
            borderWidth={6}
            borderHexColor={"333333"}
          />
          {/* Info Box to show focused image and text */}
          <div className="info-box-wrapper">
            <img
              src={images[focusElement]}
              alt={info[focusElement]}
              className="focused-image"
              style={{
                width: "30vw",
                height: "40vh",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            <h4>{heading[focusElement]}</h4>
            <p>{info[focusElement]}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facility;

// import React from "react";
// import "./facility.css";

// const Facility = () => {
//   return (
//     <div className="main-facility">
//       <div className="circular-text-wrapper">
//         <svg width="300" height="300" viewBox="0 0 300 300">
//           <path
//             id="circle-path"
//             d="M 150,150 m -100,0 a 100,100 0 1,1 200,0 a 100,100 0 1,1 -200,0"
//           />
//           <text className="circular-text">
//             <textPath href="#circle-path" startOffset="0%">
//               Mumbai • Bengaluru • Kolkata • Gujarat • Vadodara • Anand •
//             </textPath>
//           </text>
//         </svg>
//       </div>
//     </div>
//   );
// };

// export default Facility;
