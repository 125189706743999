import React from "react";
import "./coaches.css";

import coachImg from "../../Assets/realimagetwo.jpg";

const Coaches = () => {
  return (
    <>
      <h1 className="coach-head">Our Coaches</h1>
<h3 className="coaches-subhead">We Don't Just Coach, We Craft World Class-Champions</h3>
    <div className="coaches-maindiv">
      <div className="coaches-div">
        <div className="coaches-textArea">
          <p className="coaches-subtext">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
            tempora <br /> hic impedit quo reprehenderit soluta odit aliquam ratione
            eius libero!
          </p>
          <div className="coaches-text">
            <div className="coaches-onetwo">
              <div className="count">01</div>
              <div className="coaches-headtext">
                <h3>Licenses and Certifications</h3>
                <p>
                  Lorem ipsum dolor, sit amet consectetur <br /> adipisicing elit.
                  Obcaecati, repudiandae <br /> dicta cumque quisquam a harum.
                </p>
              </div>
            </div>
            <div className="coaches-onetwo">
              <div className="count" >02</div>
              <div className="coaches-headtext">
                <h3>Licenses and Certifications</h3>
                <p>
                  Lorem ipsum dolor, sit amet consectetur <br /> adipisicing elit.
                  Obcaecati, repudiandae <br /> dicta cumque quisquam a harum.
                </p>
              </div>
            </div>
          </div>
          <div className="coaches-text">
            <div className="coaches-threefour">
              <div className="count">03</div>
              <div className="coaches-headtext">
                <h3>Licenses and Certifications</h3>
                <p>
                Lorem ipsum dolor, sit amet consectetur <br /> adipisicing elit.
                Obcaecati, repudiandae <br /> dicta cumque quisquam a harum.
                </p>
              </div>
            </div>
            <div className="coaches-threefour">
              <div className="count">04</div>
              <div className="coaches-headtext">
                <h3>Licenses and Certifications</h3>
                <p>
                Lorem ipsum dolor, sit amet consectetur <br /> adipisicing elit.
                Obcaecati, repudiandae <br /> dicta cumque quisquam a harum.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="coaches-imagetext">
          <div className="coaches-blank"></div>
          <img src={coachImg} alt="" className="coachImg" />
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti <br /> saepe odio, a nam explicabo dicta dolorem sint laudantium labore laboriosam!
          </p>
        </div>
      </div>
    </div>
    
    </>
  );
};

export default Coaches;
